import Common from "../../../constants/Common";
let oneSignalInitialized = false;

export function initOneSignal() {

 const  oneSignalInitPromise = new Promise((resolve, reject) => {
    const OneSignal = window.OneSignal || [];
    OneSignal.push(async function () {
      try {
        await OneSignal.init({
          appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
          allowLocalhostAsSecureOrigin: true,
          welcomeNotification: {
            title: "LeedenNox",
            message: "Welcome to Leeden",
          },
        });
        oneSignalInitialized = true;
        resolve();
      } catch (error) {
        console.error("OneSignal initialization error:", error);
        reject(error);
      }
    });
  });

  return oneSignalInitPromise;
}

export function isOneSignalInitialized() {
  return oneSignalInitialized;
}
