import axios from "axios";
import CryptoJS from "crypto-js";
import JSEncrypt from "jsencrypt";
import _ from "lodash";
import Common from "../constants/Common";

var questionOnAir = false;
function throttle(callback, limit) {
  if (!questionOnAir) {
    questionOnAir = true; // Prevent future invocations
    callback.apply(); // Execute users function
    setTimeout(function () {
      // After a period of time
      questionOnAir = false; // And allow future invocations
    }, limit);
  }
}

export function makeiv(
  length,
  characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
) {
  var result = "";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

let TEMP_AES_KEY_STORE = {};
function encryption(config) {
  let pubKey = null;
  if (config.baseURL) {
    let uriSplit = config.baseURL.split("/");
    let API = uriSplit[uriSplit.length - 1];
    if (API === "lms") {
      let moduleAPI = config.LMSModuleAPI;
      if (config.baseURL.includes(moduleAPI)) {
        pubKey = config.LMSModulePublicKey;
      } else {
        pubKey = config.LMSPublicKey;
      }
    }
  }
  if (!pubKey) return;

  //generate AES key
  let secretPhrase = CryptoJS.lib.WordArray.random(16);
  let salt = CryptoJS.lib.WordArray.random(128 / 8);
  let aesKey = CryptoJS.enc.Utf8.parse(
    CryptoJS.PBKDF2(secretPhrase.toString(), salt).toString()
  );
  //initialization vector - 1st 16 chars of userId
  let randomStrForIv = makeiv(16);
  let iv = CryptoJS.enc.Utf8.parse(randomStrForIv);
  //start encryption
  let aesEncTrans = CryptoJS.AES.encrypt(JSON.stringify(config.data), aesKey, {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    iv: iv,
    keySize: 128 / 8,
  }).toString();
  //encrypt iv + AES key with RSA public key
  let combineKey =
    randomStrForIv.toString(CryptoJS.enc.Utf8) +
    aesKey.toString(CryptoJS.enc.Utf8);
  let rsaEncrypt = new JSEncrypt();
  rsaEncrypt.setPublicKey(pubKey);
  let rsaEncryptedAesKey = rsaEncrypt.encrypt(combineKey);
  //put in the information...
  config.headers["aes_key"] = rsaEncryptedAesKey;
  config.data = aesEncTrans;
  TEMP_AES_KEY_STORE[config.url] = combineKey;
}

function decryption(response) {
  try {
    let combineKey = TEMP_AES_KEY_STORE[response.config.url];
    if (!combineKey || typeof response.data !== "string") return;
    let iv = CryptoJS.enc.Utf8.parse(combineKey.substring(0, 16));
    let aesKey = CryptoJS.enc.Utf8.parse(combineKey.substring(16));
    let aesEncTrans = JSON.parse(
      CryptoJS.AES.decrypt(response.data, aesKey, {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: iv,
        keySize: 128 / 8,
      }).toString(CryptoJS.enc.Utf8)
    );
    response.data = aesEncTrans;
    TEMP_AES_KEY_STORE = _.omit(TEMP_AES_KEY_STORE, [response.config.url]);
  } catch (error) {
    console.log("Fail to decrypt data : caused by " + error);
  }
}

const Interceptor = {
  bugsnag: function () {
    // if (process.env.REACT_APP_ENVIRONMENT !== "LOCAL")
    //   Bugsnag.start({
    //     apiKey: "03f51759cf94567e1e45ff216ce189da",
    //     plugins: [new BugsnagPluginReact()],
    //   });
  },
  init: function () {
    axios.interceptors.request.use(
      function (config) {
        console.log("intc-1001 [REQ] url --> " + config.url);
        /******************************************************************/
        // Do something before request is sent

        if (config.method === "post" && !config.url.includes("/async/"))
          encryption(config);
        /******************************************************************/
        console.log("intc-1002 [REQ]  end ok");
        return config;
      },
      function (error) {
        console.log("intc-9001 [REQ] failed");
        /******************************************************************/
        // Do something with request error

        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        console.log("intc-2001 [RESPONSE] url --> " + response.config.url);
        /******************************************************************/
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        if (response.data && !response.config.url.includes("/async/"))
          decryption(response);

        if (
          response.headers["portal-version-code"] &&
          response.headers["portal-version-code"].toString() !==
            Common.appVersion &&
          window.location.href
            .split(window.location.origin)[1]
            .split("/")[1] !== "Customer"
        ) {
          throttle(() => {
            let question =
              "Whooa! New Version available. Refresh now to get latest?\n(This will be prompted if portal not at the latest version.)";

            if ("caches" in window) {
              caches
                .keys()
                .then((names) => {
                  return Promise.all(names.map((name) => caches.delete(name)));
                })
                .then(() => {
                  return caches.keys();
                })
                .then((namesAfterDeletion) => {
                  if (namesAfterDeletion.length === 0) {
                    console.log("All caches have been deleted.");
                  } else {
                    console.log(
                      "Some caches were not deleted:",
                      namesAfterDeletion
                    );
                  }

                  // Move the confirmation inside this .then() to ensure it's shown after cache operations
                  if (window.confirm(question)) {
                    window.location.reload(true);
                  }
                })
                .catch((error) => {
                  console.error("Error while handling caches:", error);
                });
            }
          }, 10000);
        } else if (
          response.headers["esales-portal-version-code"] &&
          response.headers["esales-portal-version-code"].toString() !==
            Common.esalesAppVersion &&
          window.location.href
            .split(window.location.origin)[1]
            .split("/")[1] === "Customer"
        ) {
          throttle(() => {
            let question =
              "Whooa! New Version available. Refresh now to get latest?\n(This will be prompted if portal not at the latest version.)";

            if ("caches" in window) {
              caches
                .keys()
                .then((names) => {
                  return Promise.all(names.map((name) => caches.delete(name)));
                })
                .then(() => {
                  return caches.keys();
                })
                .then((namesAfterDeletion) => {
                  if (namesAfterDeletion.length === 0) {
                    console.log("All caches have been deleted.");
                  } else {
                    console.log(
                      "Some caches were not deleted:",
                      namesAfterDeletion
                    );
                  }

                  // Move the confirmation inside this .then() to ensure it's shown after cache operations
                  if (window.confirm(question)) {
                    window.location.reload(true);
                  }
                })
                .catch((error) => {
                  console.error("Error while handling caches:", error);
                });
            }
          }, 10000);
        }

        /******************************************************************/
        console.log("intc-2002 [RESPONSE] end ok");
        return response;
      },
      function (error) {
        if (error.response) {
          console.log(
            "intc-9001 [RESPONSE] url --> " + error.response.config.url
          );
        } else {
          console.log("intc-9001 [RESPONSE] failed");
        }
        /******************************************************************/
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        if (error.response && error.response.data) decryption(error.response);

        return Promise.reject(error);
      }
    );
  },
};

export default Interceptor;
