import _ from "lodash";
import React from "react";
import Listener from "../../components/actions/Listener";
import { Default, DotJumping, WalkingMan } from "./type";

const LoadingEvent = "Leeden/LoadingEvent";

const Loading = {
  Default,
  DotJumping,
  WalkingMan,
};

var TEMP_KEY_STORE = [];
function LoadingIndicator() {
  const [visible, setVisible] = React.useState(false);
  const [type, setType] = React.useState("DotJumping");

  const setLoadingEvent = ({ detail }) => {
    if (detail && detail.active) {
      if (detail.key) TEMP_KEY_STORE.push({ key: detail.key });
      if (!visible) {
        setVisible(true);
        setType(detail.type || "DotJumping");
      }
    } else {
      if (detail.key)
        TEMP_KEY_STORE = _.filter(TEMP_KEY_STORE, (o) => o.key !== detail.key);
      if (TEMP_KEY_STORE.length === 0) setVisible(false);
    }
  };

  React.useEffect(() => {
    Listener.addEvent(LoadingEvent, setLoadingEvent);
    return function unmount() {
      Listener.removeEvent(LoadingEvent, setLoadingEvent);
    };
  });

  const UsedLoading = Loading[type];
  return <UsedLoading visible={visible} />;
}

export default LoadingIndicator;

export function triggerLoading(type, key) {
  window.dispatchEvent(
    new CustomEvent(LoadingEvent, { detail: { active: true, type, key } })
  );
}

export function shutLoading(key) {
  window.dispatchEvent(
    new CustomEvent(LoadingEvent, { detail: { active: false, key } })
  );
}
