const Listener = {
  triggerEvent: (event, detail) => {
    window.dispatchEvent(new CustomEvent(event, { detail }));
  },

  addEvent: (event, listener) => {
    window.addEventListener(event, listener, false);
  },

  removeEvent: (event, listener) => {
    window.removeEventListener(event, listener, false);
  },
};

export default Listener;
