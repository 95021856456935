import axios from "axios";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import MaintenanceMode from "./asset/images/gif/Maintenance.gif";
import Interceptor from "./axios/Interceptor";
import LoadingIndicator from "./axios/loading/LoadingIndicator";
import Listener from "./components/actions/Listener";
import Common from "./constants/Common";
import ReactCustomEvents from "./constants/ReactCustomEvents";
import ProjectReference from "./projects/ProjectReference";
import { initOneSignal } from "./projects/LMS/functions/OneSignalInit";

var waiting = false;
function throttle(callback, limit) {
  if (!waiting) {
    waiting = true;
    callback.apply();
    setTimeout(function () {
      waiting = false;
    }, limit);
  }
}

Interceptor.bugsnag();
Interceptor.init();

// Projects ...
const LMSRouter = React.lazy(() => import("./projects/LMS/LMSRouter"));
const CustomerRouter = React.lazy(() =>
  import("./projects/Customer/CustomerRouter")
);

function App() {
  const isMobile = useMediaQuery({ maxWidth: Common.mobileWidth });
  const [maintenanceMode, setMaintenanceMode] = React.useState(false);

  const removeScrollOnInputNumber = () => {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  };

  const initial = async () => {
    if (maintenanceMode) {
      try {
        let response = await axios.get(
          process.env.REACT_APP_API_URL + "/info/status"
        );
        setMaintenanceMode(!response.data.available);
      } catch (error) {
        setMaintenanceMode(true);
      }
    }
  };

  const turnOnMaintenanceMode = () => {
    setMaintenanceMode(true);
  };

  const moveUp = (e) => {
    let scroll = e.clientY > 900 ? 50 : e.clientY < 200 ? -50 : 0;
    if (e.clientY !== 0) {
      throttle(() => {
        let scrollUp = document.documentElement.scrollTop + scroll;
        window.scrollTo({ top: scrollUp, behavior: "smooth" });
      }, 200);
    }
  };
  React.useEffect(async () => {
    await initOneSignal();
  },[]);

  React.useEffect(() => {
    initial();
    document.addEventListener("drag", moveUp);
    document.addEventListener("wheel", removeScrollOnInputNumber);
    Listener.addEvent(ReactCustomEvents.maintenanceMode, turnOnMaintenanceMode);
    
      // const OneSignal = window.OneSignal || [];
      // OneSignal.push(async function () {
      //   try {
      //     await OneSignal.init({
      //       appId: Common.onesignal_app_id,
      //       allowLocalhostAsSecureOrigin: true,
      //       welcomeNotification: {
      //         title: "LeedenNox",
      //         message: "Welcom to Leeden",
      //       },
      //     });
      //     await OneSignal.setSubscription(true);
      //   } catch (error) {
      //     console.log(error);
      //   }
      // });
    

    return () => {
      document.removeEventListener("drag", moveUp);
      document.removeEventListener("wheel", removeScrollOnInputNumber);
      Listener.removeEvent(
        ReactCustomEvents.maintenanceMode,
        turnOnMaintenanceMode
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (maintenanceMode) {
    return (
      <BrowserRouter>
        <Switch>
          <Route path={"/"}>
            <div
              className={"d-flex align-items-center justify-content-center"}
              style={{ width: "100vw", height: "100vh" }}
            >
              <div className={"d-flex flex-column align-items-center"}>
                <img src={MaintenanceMode} alt={"maintenance-mode-gif"} />
                <h2>Website under maintenance</h2>
                <div>
                  This website is currently undergoing scheduled maintenance. We
                  should be back shortly.
                </div>
              </div>
            </div>
          </Route>
          <Route render={() => <Redirect to={"/"} />} />
        </Switch>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <LoadingIndicator />
        <Switch>
          {/* Project path -- add new project here --*/}
          <Route path={ProjectReference.LMS}>
            <React.Suspense fallback={<React.Fragment></React.Fragment>}>
              <LMSRouter />
            </React.Suspense>
          </Route>
          <Route path={ProjectReference.Customer}>
            <React.Suspense fallback={<React.Fragment></React.Fragment>}>
              <CustomerRouter />
            </React.Suspense>
          </Route>
          {/* Handle invalid url... */}
          <Route exact path={"/"} component={null} />
          <Route render={() => <Redirect to={"/"} />} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
