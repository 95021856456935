import styled from "styled-components";

const LoadingBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(246, 247, 248, 0.5);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: ${(props) => (props.visible ? 1 : 0)};
  height: ${(props) => (props.visible ? "auto" : 0)};
  overflow: hidden;
  transition: opacity 0.8s;

  div#wave {
    .dot {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 3px;
      animation: wave 1.3s linear infinite;
      /* animation-delay: 10s; */
      &:nth-child(2) {
        animation-delay: -1.1s;
      }
      &:nth-child(3) {
        animation-delay: -0.9s;
      }
      &:nth-child(4) {
        animation-delay: -0.7s;
      }
    }
  }

  @keyframes wave {
    0%,
    60%,
    100% {
      transform: initial;
      background: rgb(0, 190, 242);
    }
    30% {
      transform: translateY(-15px);
      background: rgb(95, 177, 128);
    }
  }
`;

function DotJumping({ visible }) {
  return (
    <LoadingBackground visible={visible}>
      <div id="wave">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </LoadingBackground>
  );
}

export default DotJumping;
