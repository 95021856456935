import styled from "styled-components";

const LoadingBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: ${(props) => (props.visible ? 1 : 0)};
  height: ${(props) => (props.visible ? "auto" : 0)};
  overflow: hidden;
  transition: opacity 0.8s;
`;

function Default({ visible }) {
  return (
    <LoadingBackground visible={visible}>
      <div className="k-loading-mask">
        <div className="k-loading-image"></div>
        <div className="k-loading-color"></div>
      </div>
    </LoadingBackground>
  );
}

export default Default;
