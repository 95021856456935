import "@progress/kendo-theme-material/dist/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import "react-image-gallery/styles/css/image-gallery.css";
import { Provider } from "react-redux";
import "./animation.css";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import { initOneSignal } from "./projects/LMS/functions/OneSignalInit";


if ('serviceWorker' in navigator) {
  // Unregister the old service worker if it still exists
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      if (registration.active && registration.active.scriptURL.includes('service-worker.js')) {
        registration.unregister().then(function (success) {
          if (success) {
            console.log('Old service worker unregistered.');
          } else {
            console.log('Failed to unregister old service worker.');
          }
        });
      }
    }
  });
  

}


const ErrorBoundary =
  // process.env.REACT_APP_ENVIRONMENT === "LOCAL"
  //   ?
  (props) => <React.Fragment>{props.children}</React.Fragment>;
// : Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);



reportWebVitals();
