import styled from "styled-components";
import WalkingManGif2 from "../../../asset/images/gif/WalkingMan2.gif";

export const WalkingManGif = () => (
  <img src={WalkingManGif2} alt={"walking-man-gif"} />
);

const LoadingBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(246, 247, 248, 0.2);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: ${(props) => (props.visible ? 1 : 0)};
  height: ${(props) => (props.visible ? "auto" : 0)};
  overflow: hidden;
  transition: opacity 0.8s;

  img:first-child {
    position: absolute;
  }
`;

function WalkingMan({ visible }) {
  return (
    <LoadingBackground visible={visible}>
      <WalkingManGif />
    </LoadingBackground>
  );
}

export default WalkingMan;
